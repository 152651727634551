.content {
  min-height: 79vh;
}

th .hideColumnButton {
  display: none;
}
th:hover .hideColumnButton {
  display: initial;
}
